<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-light bg-white"
    id="sidenav-main-mobile">
    <div class="modalDiv" :style="this.openDialogProgress" >
      <div class = "progressInfo"><span class="progressText" v-html="progressMessage"></span></div>
      <div class = "progressBar"><progress-bar ref=link :options="options" :value="progressValue"/> </div>
      <button class = "closeBtn" @click="hideModal">x</button>
  </div>
    <div class="container-fluid">
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse navbar-mobile collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header-mobile d-lg-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul id="linksIdMobile" class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
        <!--<h6 class="navbar-heading text-muted">Documentation</h6>-->
        <!--Navigation-->
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" @click="logout">
              <i class="fas fa-sign-out-alt"></i> {{ $t("logout") }}
            </a>
          </li>
        </ul>
      </div>
      <div class="logoImg" style="">
      <router-link class="navbar-brand" to="/">
        <img width="100" height="50" :src="logo" class="" alt="..." />
      </router-link>

      </div>
      <div class="navbar-nav ml-auto mr-3">
        <base-dropdown>
          <base-button slot="title" type="secondary" class="dropdown-toggle">
            {{ this.selectedLang }}
          </base-button>
          <a @click="changeLang('en')" class="dropdown-item"
            >English</a>
          <a @click="changeLang('de')" class="dropdown-item" 
            >Deutsch</a>
        </base-dropdown>
      </div>
    </div>
    <a 
        @click=this.displayDownloadDialog
        :style=this.downloadCursorStyle
        :title=this.toltipTitle
        class="mobileLink"
        >{{ $t("download") }}
      </a>
    <div class="float-right">
        <a :href="this.helpLink" class="mobileLink" target="_blank" >{{ $t("help") }}</a>
        <a :href="this.legalLink" class="mobileLink" target="_blank">{{ $t('legalNotice') }}</a> 
        <a :href="this.privacyLink" class="mobileLink" target="_blank">{{ $t('privacyPolicy') }}</a>  
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import { auth } from "../../store/authModule";
import i18n from "../../i18n.js";
import Vue from 'vue';
import {eventBus} from "../../main.js";
import vmodal from 'vue-js-modal'

Vue.use(vmodal, { dialog: true, resizable:true })


export default {
  name: "sidebarMobile",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  data() {
    return {
            
      openDialogProgress:"display:none",
      progressMessage: "",
      progressValue:0,
      downloadActive: false,
      downloadCursorStyle : "",
      toltipTitle:"",
      windowURL:"",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333'
        },
        layout: {
          height: 20,
          width: 100,
          verticalTextAlign: 72,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      },
      selectedLang: i18n.t("language"),
      privacyLink: i18n.t("privacyLink"),
      legalLink: i18n.t("legalLink"),
      helpLink: i18n.t("helpLink"),
      androidLink: "<a href='https://play.google.com/store/apps/details?id=com.digithurst.hdscode2'>Google Play Store</a>",
      iosLink: "<a href='https://apps.apple.com/us/app/healthdataspace-access-code/id1542947265'>AppStore</a>"

    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },

  mounted() {  
    this.windowURL = window.location.href;
    
    eventBus.$on('updateProgressText', (text) => {
      this.progressMessage =  this.$t(text);
    });

    eventBus.$on('updatePb', (value) => {        
      this.progressValue = value;
    });

    eventBus.$on('downloadDone', () => {
      this.downloadCursorStyle = "";
      this.downloadActive = false;
      this.openDialogProgress = "display:none";
      this.toltipTitle = ""

    });

    //always set to default language -> which was set on login
    if (this.$session.get('default_lang')) {

      i18n.locale = this.$session.get('default_lang');
      
      this.selectedLang = i18n.t("language");
    }
  },

  methods: {

    hideModal() {
      this.openDialogProgress = "display:none";
    },

    downloadReport() {

      var report =  Vue.prototype.$activeReport;
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      if (Vue.prototype.$documentName .indexOf('.pdf') == -1) {
        Vue.prototype.$documentName  += '.pdf'
      }
      
      a.href = report;
      a.download = Vue.prototype.$documentName;
      a.click();
      window.URL.revokeObjectURL(report);

      eventBus.$emit('downloadDone');
    },

    displayDownloadDialog() {

      if (Vue.prototype.$activeDocument == "report") {
        this.downloadReport()
      }else {

      this.$modal.show('dialog',  {
          title: this.$t('download'),
          text: this.$t('downloadTextStart') + this.androidLink + this.$t('downloadTextEnd') + this.iosLink,
          clickToClose : true,
          adaptive: true,
          resizable: true,      
        }) 
      }
    },

    changeMedviewLang(lang) {

      document.head.removeChild(this.$medviewScript)  

      let script = document.createElement('script')
      script.setAttribute('src', "medview/STABLE/medview/MedView_" + lang + ".js");

      document.head.appendChild(script)  
      Vue.prototype.$medviewScript = script;

      var emitEventCallback = function() {
        eventBus.$emit('changeMedviewLang');
      }

      var el =  document.getElementById("diagnosticWarning");
      el.parentNode.removeChild(el);

      if (this.$route.name == "study") {
        setTimeout(function(){
          emitEventCallback();
        }, 500);
      }      
    },

    changeLang(lang) {

      i18n.locale = lang;
    
      this.privacyLink = i18n.t("privacyLink");
      this.legalLink = i18n.t("legalLink");
      this.helpLink = i18n.t("helpLink");

      this.changeMedviewLang(lang)

      if (lang == "de") {
        this.selectedLang = "Deutsch"      
      }   else {
        this.selectedLang = "English"      
      }  
    },

    logout() {
      auth.mutations.logout();
      this.$router.push("/login");
    },

    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style >

.logoImg {
   margin-left: auto;
   
}
.navbar {
  padding-top: 10px !important;
}

.nav-link {
  cursor: pointer;
}
.mobileLink {
  font-size: 12px;
  margin-right: 10px;
}

.closeBtn {
  background-color:#dfdfdf; 
  border:0px;
  position:absolute;
  top:0;
  right:5px;
  height:25px;
}

.modalDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:999999;
  opacity:0.9;
  width:260px;
  height:40px;
  border-radius: 5px;
  background-color:#dfdfdf;
}

</style>