/* global dh */
import axios from 'axios';
import authHeader from '../services/authHeader';
import { auth } from './authModule';
import studyStore from './studyStore';
import reportStore from './reportStore';
import moment from 'moment';

const STUDY = 'study';
const REPORT = 'report';

const AccountStore = {
  data: {
    account: {},
    menuItems: [],
    fetched: false,
    currentAesKey: null,
    currentRsaKey: null,
    
  },
  methods: {
    clean(){
      AccountStore.data.account = {};
      AccountStore.data.menuItems = [];
      AccountStore.data.fetched = false;
      AccountStore.data.currentAesKey = null;
      AccountStore.data.currentRsaKey = null;
    },
    async fetch(hac, callback, errorCallback) {
      axios.get('/v1/accounts', { headers: authHeader() })
      .then(response => {
        
        //hac empty
        if (response.data.data.entries == null) {
          errorCallback(("hacEmpty"));
          return; 
        }

        AccountStore.data.account = response.data.data;
        localStorage.setItem("expirationDateDicom", response.data.data.expirationDateDicom)
        let account = AccountStore.data.account;
        var entries = account.entries;
        AccountStore.data.menuItems = [];
        
        for (var i = 0; i < entries.length; i++) {
          var entry = entries[i];
          var icon = 'study';
          if(entry.type == REPORT){
            icon = 'report';
            if(!AccountStore.data.activeReport){
              reportStore.data.reports[entry.id] = entry;
              if(!reportStore.data.activeReport){
                let storedReportId = localStorage.getItem('currentReportId');
                if(storedReportId && typeof storedReportId !== 'undefined'){
                  reportStore.data.activeReport = reportStore.data.reports[storedReportId];
                }else{
                  reportStore.data.activeReport = entry;
                }
              }
            }

          }else if(entry.type == STUDY){
            // add to studyStore
            studyStore.data.studies[entry.id] = entry;
            if(!studyStore.data.activeStudy){
              let storedStudyId = localStorage.getItem('currentStudyId');
              if(storedStudyId && typeof storedStudyId !== 'undefined'){
                studyStore.data.activeStudy = studyStore.data.studies[storedStudyId];
              }else{
                studyStore.data.activeStudy = entry;
              }
            }
          }
          var typeString = entry.type.charAt(0).toUpperCase() + entry.type.slice(1);
          var name = entry.name;
          if(!name || typeof name == 'undefined' || name.length == 0){
            name = entry.description;
          }
          if(!name || typeof name == 'undefined' || name.length == 0){
            name = "noName";
          }


          var dateValidation = moment(entry.study_date_time);
          var studyDate = null;

          if (dateValidation.isValid()) {
            studyDate = new Date(entry.study_date_time ? entry.study_date_time : entry.date_time)
          }

          var item = {id: i, link:{id: entry.id, name: name, date: studyDate, documentType: entry.type, desc: typeString, icon: icon, path: "/"+entry.type}};
          AccountStore.data.menuItems.push(item);
        }
        if(entries.length > 0){
          auth.actions.decrpytUserKey(hac, AccountStore.data.account.key.user, async (err, decRsaKey) => {
            if(err){
              throw err;
            }
            AccountStore.data.currentRsaKey = decRsaKey;
            AccountStore.data.currentAesKey = auth.actions.decryptAesKey(AccountStore.data.currentRsaKey, response.data.data.key.content);

            let sCrypto = dh.crypto.createSymetricCryptor(AccountStore.data.currentAesKey);
            if (account.encryptedBirthdate){
            account.birthdateDecrypted = sCrypto.toUtf8(sCrypto.decrypt(account.encryptedBirthdate));
            }
            else{
              account.birthdateDecrypted = account.birthdate;
            }
            account.nameDecrypted = sCrypto.toUtf8(sCrypto.decrypt(account.name));
            account.firstNameDecrypted = sCrypto.toUtf8(sCrypto.decrypt(account.firstName));
            account.fullNameDecrypted = sCrypto.toUtf8(sCrypto.decrypt(account.fullName));
            if (! account.fullNameDecrypted)
              account.fullNameDecrypted = account.nameDecrypted + "^" + account.firstNameDecrypted;
                       

            AccountStore.data.fetched = true;

            AccountStore.data.menuItems.sort((a, b) => {
              if (a.link.documentType === "study" && b.link.documentType !== "study") {
                return -1; 
              } else if (a.link.documentType !== "study" && b.link.documentType === "study") {
                return 1; 
              } else {
                return 0; 
              }
            });
            
            callback(entries);
          });
        }else{
          if(callback){
            callback();
          }
        }
        
      }).catch(e => {
        console.log(e);
        errorCallback(e);
      })
    }
  }
}

export default AccountStore;
