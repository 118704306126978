import axios from 'axios';

export default function authHeader() {

    if (!localStorage.getItem('user'))
      return;
    
    let user = JSON.parse(localStorage.getItem('user'));
    var expires = new Date (user.expiresAt);
    let minutes = parseInt(Math.abs(new Date().getTime() - expires.getTime()) / (1000 * 60) % 60);

    //if token is valid under 15 minutes extend it    
    if (minutes <= 15) {
      axios.post('/login/refresh', {
        grant_type: "refresh_token", refresh_token: user.refresh_token
      }).then(function (response) {
        response.data.expiresAt = new Date().getTime() + (response.data.expires_in * 1000);
        localStorage.setItem('user', JSON.stringify(response.data));
      })
    }

    if (user && user.access_token) {
      return { Authorization: 'Bearer ' + user.access_token };
    } else {
      return {};
    }
  }
  