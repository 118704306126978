import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'

Vue.use(Router)

export const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
      ]
    },
    {
      path: '/',
      component: DashboardLayout,
      children: [
        {
          path: '/report/:id',
          name: 'report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Report.vue')
        },
        {
          path: '/study/:id',
          name: 'study',
          component: () => import(/* webpackChunkName: "demo" */ './views/Medview.vue')
        },
        {
          path: '/home',
          name: 'home',
          component: () => import(/* webpackChunkName: "demo" */ './views/Home.vue')
        }
   
      ]
    }
  ]
});
