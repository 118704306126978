import axios from 'axios';
import authHeader from '../services/authHeader';
import { auth } from './authModule';
import accountStore from './accountStore';
import Vue from 'vue'

const ReportStore = {
  data: {
    reports: [],
    activeReport: null,
    currentReportKey: null
  },
  methods: {
    clean(){
      ReportStore.data.reports = {};
      ReportStore.data.activeReport = null;
      ReportStore.data.currentReportKey = null;
    },
    loadReport(callback){
      if(!accountStore.data.fetched){
        var localCallback = function(){
          ReportStore.methods.getReportKeyAndLoad(callback);
        }
        accountStore.methods.fetch(null, localCallback);
      }else{
        ReportStore.methods.getReportKeyAndLoad(callback);
      }
    },

    getReportKeyAndLoad(callback){
      axios.get('/v1/keys/report/' + ReportStore.data.activeReport.id, { headers: authHeader() })
      .then(response => {
      
        var decryptedAesKey = auth.actions.decryptAesKey(accountStore.data.currentRsaKey, response.data.data);
        ReportStore.data.currentReportKey = decryptedAesKey;
        Vue.prototype.$documentName = ReportStore.data.activeReport.name;
        
        callback();
      }).catch(e => {
        console.log(e);
      })
    },
  }
}

export default ReportStore;