<template>
  <div class="documentLink"> 
    <router-link :to="{ path: '/' + link.documentType + '/' + link.id }" @click.native="linkClick(link.id, link.path, link)"
      class="nav-link documentLink" :target="link.target" :href="{ path: '/' + link.documentType + '/' + link.id }">
      <template>
        <img style="margin-right:10px" v-if="link.icon === 'study'" width="25px" height="25px"
          src="img/icons/thorax.png" />
        <img style="margin-right:10px" v-if="link.icon === 'report'" width="25px" height="25px"
          src="img/icons/report.png" />
        <div v-if="isLongName(link.name)" class="linkContainer">
          <div v-tooltip="{ content: getWholeName(link.name), show: false, placement: 'top' }" class="navLink"
            style="padding: 5px; float: left;">
            <span class="nav-link-text">{{ getName(link.name) }}</span>
            <br>
            <span style="font-size:12px" class="nav-link-text">{{ getDesc(link.desc) }} {{ link.date | formatDate }} </span>
          </div>
          <div v-bind:style="{ display: this.downloadBtnDisplay }" :id="mobile?link.id+ 'mobile': link.id"
            v-on:click="downloadClicked(link, link.documentType)" class="iconClick disabled" style="float:right;"> <img
              style="margin-top: 20px" src="img/icons/dl.png" />
          </div>
        </div>
        <div class="linkContainer" v-else>
            <div class="navLink">
              <span class="nav-link-text">{{ getName(link.name) }}</span>
              <br>
              <span style="font-size: 12px" class="nav-link-text">{{ getDesc(link.desc) }} {{ link.date | formatDate }}</span>
            </div>
            <div
              v-bind:style="{ display: this.downloadBtnDisplay }"
              :id="mobile ? link.id + 'mobile' : link.id"
              v-on:click="downloadClicked(link, link.documentType)"
              class="iconClick disabled download-btn"
            >
              <img style="margin-top: 20px" src="img/icons/dl.png" />
            </div>
        </div>
      </template>
    </router-link>
    <div style="display:none;">      
      <div class="progressInfo"><span class="progressText" v-html="progressMessage"></span></div>
      <div class="progressBar">
        <div id="loadingSpinnerDownload"></div><progress-bar ref=link :options="options" :value="progressValue" />
      </div>
    </div>
  </div>
</template>
<script>

//global variable
window.downloadActive = false;

import moment from 'moment';
import studyStore from '../../store/studyStore';
import AccountStore from '../../store/accountStore';
import StudyStore from "../../store/studyStore";
import ReportStore from "../../store/reportStore";
import { eventBus } from "../../main.js";
import ProgressBar from 'vuejs-progress-bar'
import Vue from 'vue'
import downloadContent from '../../dicom/download';
import vmodal from 'vue-js-modal'
import VTooltip from 'v-tooltip'

import VuejsDialog from "vuejs-dialog";

Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  cancelText: "Cancel",
  animation: "zoom",
});

import CustomDownloadView from "@/views/Download"
const customDownloadView = "my-unqiue-test-view-name";
Vue.dialog.registerComponent(customDownloadView, CustomDownloadView);

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
});

Vue.use(VTooltip)
Vue.use(ProgressBar)
Vue.use(vmodal, { dialog: true, resizable: true })

export default {
  name: 'sidebar-item',
  props: {
    mobile:{},
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          desc: '',
          path: '',
          children: [],
          activeProgress: ''
        };
      },
      description:
        'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
    }
  },
  inject: {
    autoClose: {
      default: true
    }
  },

  data() {
    return {
      downloadBtnDisplay: "",
      progressMessage: "",
      children: [],
      collapsed: true,
      progressValue: 0,
      selectedDocument : "",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333'
        },
        layout: {
          height: 20,
          width: 100,
          verticalTextAlign: 72,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  created() {
    this.onResize();
    eventBus.$on('updateProgressText', (text) => {
      this.progressMessage = this.$t(text);
    });

    //enable dl button when study is loaded but only if no download is active
    eventBus.$on('documentLoaded', (id) => {
      if (window.downloadActive == false) {
        document.getElementById(id).classList.remove("disabled");
      } else {
        document.getElementById(id).classList.add("disabled");
      }
    });

    eventBus.$on('startDownload', (selected) => {
      if(this.selectedDocument !== "")
        this.downloadDocument(this.selectedDocument, "study", selected);
    });

    //download done -> enable buttons for downloaded 
    eventBus.$on('downloadDone', (studyId) => {

      window.downloadActive = false;

      if (studyId) {
        document.getElementById(studyId).classList.remove("disabled");
      }

      //hide progress bar
      if (this.activeProgress) {
        this.activeProgress.style = "display:none"
      }

    })

    //update progress bar
    eventBus.$on('updatePb', (value) => {
      this.progressValue = value;
    })
  },


  methods: {

    isLongName(name) {
      if (name.length > 20) {
        return true;
      }
      return false;
    },


    getWholeName(name) {
      if (name == "noName") {
        return this.$t(name);
      } else {
        return name;
      }
    },

    getName(name) {

      let returnName;

      if (name == "noName") {
        returnName = this.$t(name);
      } else {
        returnName = name;
      }

      if (returnName.length > 20) {
        returnName = returnName.slice(0, 15) + "...";
      }

      return returnName;
    },
    getDesc(desc) {
      return this.$t(desc.toLowerCase());
    },
    onResize() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      var mql = window.matchMedia("screen and (max-width: 765px)")

      if (isMobile || mql.matches) {
        this.downloadBtnDisplay = "none"
      } else {
        this.downloadBtnDisplay = "block"
      }
    },

    startDownloadView(){
        this.$dialog.confirm(null, {
          view: customDownloadView,
          html: true,
          animation: "zoom",
          backdropClose: true,
        });
    },

    downloadDocument(link, documentType, selected) {

      //download active disable other downloads
      document.getElementsByClassName('iconClick').forEach(function (entry) {
        entry.classList.add("disabled");
      });

      if (window.downloadActive == true) {
        return;
      }

      if (documentType == "report") {

        var report = Vue.prototype.$activeReport;
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        if (link.name.indexOf('.pdf') == -1) {
          link.name += '.pdf'
        }

        a.href = report;
        a.download = link.name;
        a.click();
        window.URL.revokeObjectURL(report);

        eventBus.$emit('downloadDone');

      }
      else if (documentType == "study") {

        //display and mark progress bar            
        this.$el.children[1].style = "display:block";
        this.activeProgress = this.$el.children[1];


        //reset progress bar
        this.progressValue = 0;
        window.downloadActive = true;

        downloadContent(StudyStore.data.activeStudy.id, selected);

      } else {
        console.log("unknown document type")
      }

    },

    downloadClicked(link, documentType) {

      //prevent passing event 
      event.preventDefault()
      event.stopPropagation()

      var me = this;

      if (documentType == "study") {
       /* me.$modal.show('dialog', {
          title: this.$t('download'),
          text: this.$t('downloadType'),
          clickToClose: true,
          adaptive: true,
          resizable: true,
          buttons: [
            {
              title: 'DICOM & JPEG',
              handler: () => {
                me.downloadDocument(link, documentType, "all")
                me.$modal.hide('dialog')
              }
            },
            {
              title: 'DICOM',
              handler: () => {
                me.downloadDocument(link, documentType, "dicom")
                me.$modal.hide('dialog')
              }
            },
            {
              title: 'JPEG',
              handler: () => {
                me.downloadDocument(link, documentType, "jpeg")
                me.$modal.hide('dialog')
              }
            }
          ]
        })*/
        this.selectedDocument = link;
        this.startDownloadView();
      } else if (documentType == "report") {
        me.downloadDocument(link, "report");
      }
    },

    linkClick(id, path, link) {

      if (path == "/study") {
        Vue.prototype.$activeDocument = "study";
        StudyStore.data.activeStudy = StudyStore.data.studies[id];
        localStorage.setItem('currentStudyId', StudyStore.data.activeStudy.id);
      } else {
        Vue.prototype.$activeDocument = "report"
        Vue.prototype.$documentName = link.name;
        ReportStore.data.activeReport = ReportStore.data.reports[id];
        localStorage.setItem('currentReportId', ReportStore.data.activeReport.id);
        eventBus.$emit('linkClickedReport');
      }

      eventBus.$emit('linkClicked');
      //click on link disable all download icons
      document.getElementsByClassName('iconClick').forEach(function (entry) {
        entry.classList.add("disabled");
      });

      //do not disable clicked one
      if (window.downloadActive == false) {
        document.getElementById(id).classList.remove("disabled");
      }

      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
      studyStore.data.activeStudy = AccountStore.data.account.entries[this.$vnode.key];
    }
  }
}

</script>

<style >

.documentLink {
  
  max-height:115px;
}

.innerLink {
  max-height:115px;
}

.linkContainer {
  width:100%;
  height:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.navLink {
  width: 145px;
}

.download-btn {
  float: right;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.progressDiv {
  margin: auto;
  margin: 0 auto;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.progressInfo {
  float: left;
  height: 20px;
  padding: 5px;
  width: 125px;
  margin-left: 5px;
}

.progressBar {
  margin-left: 110px;
  padding: 5px;
}

.progressText {
  color: green;
  font-size: 10px;
}

#loadingSpinnerDownload {
  float: left;
  margin-right: 2px;
  margin-top: 7px;
  width: 14px;
  height: 14px;
  border: 3px solid gray;
  border-radius: 50%;
  border-top-color: #f9f9f9;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}</style>