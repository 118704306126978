<template>
  <nav  style="overflow:auto" class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <base-dropdown>
        <base-button style="margin: 5px" slot="title" type="secondary" class="dropdown-toggle">
          {{ this.selectedLang }}
        </base-button>
        <a @click="changeLang('en')" class="dropdown-item">English</a>
        <a @click="changeLang('de')" class="dropdown-item">Deutsch</a>
      </base-dropdown>
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/home">
        <img :src="logo" class="navbar-brand-img" />
      </router-link>
      <div v-show="$sidebar.showSidebar" class="outer-div navbar-collapse collapse show" id="sidenav-collapse-main">
        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <hr class="my-3" />
            <a href="" class="nav-link" @click="logout">
              <i class="fas fa-sign-out-alt"></i> {{ $t("logout") }}
            </a>
          </li>
      </ul>
      </div>
      <ul class="navbar-nav mb-md-3">      
        <li class="nav-item">
          <a :href="this.helpLink" class="nav-link nav-link-contained" target="_blank">{{ $t("help") }} </a>
        </li>
        <li class="nav-item">
          <a :href="this.legalLink" class="nav-link nav-link-contained" target="_blank">{{
            $t("legalNotice")
          }}</a>
        </li>
        <li class="nav-item">
          <a :href="this.privacyLink" class="nav-link nav-link-contained" target="_blank">{{
            $t("privacyPolicy")
          }}</a>
        </li>
        <li class="nav-item">
          <div class="tvWebDiv">
            <div class="logoDiv">
              <img width="25px" height="25px" src="img/tvweb/teamviewlogo.svg" />
              <img width="113px" height="60px" src="img/tvweb/tvweblogo.svg" />
            </div>
            {{ $t("tvWebText") }}
            <div class="tvWebBtn" v-on:click="startTeamView">
              {{ $t("start") }}
            </div>
          </div>
        </li>
      </ul>
      <div v-if="this.expirationDate" class="expiresText">
        <div style="float:left">
          {{ $t("expires") }}: {{ this.expirationDate | formatDate }}
        </div>
        <div style="float:right">
          <slot name="switcher"></slot>
        </div>
      </div>
      <div v-else class="expiresText">
        <div style="float:right">
          <slot name="switcher"></slot>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import { auth } from "../../store/authModule";
import i18n from "../../i18n.js";
import Vue from "vue";
import VueSession from "vue-session";
import { eventBus } from "../../main.js";
import AccountStore from "../../store/accountStore";
import VueSimpleAlert from "vue-simple-alert";

import VuejsDialog from "vuejs-dialog";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
Vue.use(VueSimpleAlert);

Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  cancelText: "Cancel",
  animation: "zoom",
});

import CustomView from "@/views/HDSLogin";
const customView = "my-unique-view-name";
Vue.dialog.registerComponent(customView, CustomView);
Vue.use(VueSession);

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  data() {
    return {
      medviewScript: null,
      selectedLang: i18n.t("language"),
      privacyLink: i18n.t("privacyLink"),
      legalLink: i18n.t("legalLink"),
      helpLink: i18n.t("helpLink"),
      expirationDate: null,
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },

  mounted() {

    if (AccountStore.data.account.expirationDateFinal) {
      localStorage.setItem("expirationDate", AccountStore.data.account.expirationDateFinal);
      this.expirationDate = AccountStore.data.account.expirationDateFinal;
    } else if (localStorage.getItem("expirationDate") != null) {
      this.expirationDate = localStorage.getItem("expirationDate");
    }

    //always set to default language -> which was set on login
    if (this.$session.get("default_lang")) {
      i18n.locale = this.$session.get("default_lang");
      this.selectedLang = i18n.t("language");
    }
  },

  methods: {
    startTeamView() {
      if (this.isMobile()) {
        this.$alert(i18n.t("tvWebMobileWarning"));
      } else if (!this.browserEngineSupported()) {
        this.$alert(i18n.t("tvWebBrowserWarning"));
      } else {
        this.$dialog.confirm(null, {
          view: customView,
          html: false,
          animation: "zoom",
          backdropClose: false,
        });
      }
    },
    changeLang(lang) {
      i18n.locale = lang;

      this.privacyLink = i18n.t("privacyLink");
      this.legalLink = i18n.t("legalLink");
      this.helpLink = i18n.t("helpLink");

      this.changeMedviewLang(lang);

      if (lang == "de") {
        this.selectedLang = "Deutsch";
      } else {
        this.selectedLang = "English";
      }
    },

    changeMedviewLang(lang) {
      document.head.removeChild(this.$medviewScript);

      let script = document.createElement("script");
      script.setAttribute("src", "medview/STABLE/medview/MedView_" + lang + ".js");

      document.head.appendChild(script);
      Vue.prototype.$medviewScript = script;

      var emitEventCallback = function () {
        eventBus.$emit("changeMedviewLang");
      };

      var el = document.getElementById("diagnosticWarning");
      if (el) {
        el.parentNode.removeChild(el);
      }

      if (this.$route.name == "study") {
        setTimeout(function () {
          emitEventCallback();
        }, 500);
      }
    },

    logout() {
      auth.mutations.logout();
    },

    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    browserEngineSupported() {
      // Safari
      let isWebKitnavigator = navigator.userAgent.includes("AppleWebKit");
      // Firefox
      let isGecko = !isWebKitnavigator && navigator.userAgent.includes("Gecko");
      // Detect Blink engine (Chrome/Chromium, Edge>=79, Opera>=15)
      let isBlink = navigator.userAgent.includes("Chrome");

      return isGecko || isBlink;
    },
    isMobile() {
      let containsMobi = navigator.userAgent.includes("Mobi");
      let isTablet = navigator.userAgent.includes("Tablet");
      return containsMobi || isTablet;
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    },
  },
};
</script>
<style>

.navbar-brand-img {
  width: 144px;
  height: auto; 
  max-width: 144x; 
  object-fit: contain; 
}

.expiresText {
  font-size: 11px;
}

.logoDiv {
  text-align: center;
}

.tvWebDiv {
  background: #288b94;
  width: 200px;
  height: 170px;
  margin: auto;
  border-radius: 6px;
  font-size: 10px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.tvWebBtn {
  color: white;
  height: 22px;
  width: 120px;
  margin: auto;
  background-color: #33b1bd;
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}

.nav-link-contained {
  max-height: 32px;
}

.dg-btn--ok {
  background-color: #0daebc;
  color: white;
  border: 0px;
}

.dg-btn--cancel {
  background-color: #d0d0d0;
  color: white;
  border: 0px;
}

.dg-content-cont--floating {
  top: 55%;
}

.dg-main-content {
  text-align: justify;
  background-color: #228c95;
  width: 650px !important;
  max-width: 650px;
  padding: 0px;
}

.dg-view-wrapper {
  background-color: #228c95;
}

.dg-btn-loader .dg-circle {
  background-color: green;
}
</style>
