"use strict";
import dcmjs from "dcmjs";

export async function setPatientInfo(dicom, patient) {
    let DicomDict = dcmjs.data.DicomMessage.readFile(dicom.content);    

    DicomDict.dict['00100010'] = {vr: 'PN', Value: [patient.name]};
    DicomDict.dict['00100020'] = {vr: 'LO', Value: [patient.id]};
    DicomDict.dict['00100030'] = {vr: 'DA', Value: [getDicomDate(patient.birthdate)]};
    DicomDict.dict['00100040'] = {vr: 'CS', Value: [patient.sex.toUpperCase()]};    

    return {
        content: DicomDict.write(),
        filename: dicom.filename
    }
}

function getDicomDate(birthdate) {        
    let year = birthdate.getFullYear();
    let month = trimDateString(birthdate.getMonth() + 1);
    let day = trimDateString(birthdate.getDate());

    return year+""+month+""+day;
}

function trimDateString(input) {
    if (input < 10) {
        return "0"+input;
    }
    return input;
}