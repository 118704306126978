
/**
 * Starts teamVIEW web viewer.
 * tvweb opens in a new Tab. If tvweb is already open in a tab it will reuse it
 * To use tvweb with several monitors the tvweb Browser extension is needed.
 * @param {JWT} [jwt=null] Jwt token to authenticate to Endpoints -- unused
 * @param {Array} studyList List of studyJsons to be opened in tvweb
 * @param {Object} endpoints contains endpoints tvweb can communicate with. Currently only renderServer: renderserverUrl is supported
 * @param {Object} [exportTarget=null] What to use for the data export -- unused
 * @param {string} [tvwebUrl=""]
 * @returns Promise that resolves to true, when tvweb has successfully been opened
 */
export async function startTVweb(jwt=null, studyList, endpoints, exportTarget = null, tvwebUrl = "") {

	// validate input parameters
	if (!studyList?.length){
		throw "No studies provided";
	}

	if (!endpoints){
		throw "No endpoints provided";
	}
	if (!endpoints?.renderServer){
		throw '"renderServer" endpoint is mandatory';
	}

	// Try to create a URL Object from each endpoint. 
	// Throws if endpoint string has invalid url format
	Object.values(endpoints).forEach(ep => {
		new URL(ep);
	});

	// Throws on invalid tvwebURL
	if (tvwebUrl){new URL(tvwebUrl);}


	// Needs to be defined as global variables, otherwise it is not correctly updated
	// in the Broadcast Channel callback on subsequent calls
	window.tvwebParams = {
		jwt: jwt,
		studyList: studyList,
		endpoints: endpoints,
		exportTarget: exportTarget
	};

	//commented code due to incompatibility with the code2 (TODO fix in next tvWEB version)
	/*
	if (!tvwebUrl && !import.meta?.url) {
		throw "Could not determine location of tvweb from module location -- please provide url to teamview_web.html in tvwebUrl parameter";
	}
	if (!tvwebUrl) {
		const moduleUrl = import.meta?.url;
		const tvwebDir = moduleUrl.substring(0, moduleUrl.lastIndexOf('/'));
		tvwebUrl = tvwebDir + "/teamview_web.html";
	}
	*/

	if (document.getElementById('tvwebBrowserExtensionInstalled')) {
		await sendMessageToExtension("startTVweb", { tvwebUrl: tvwebUrl, tvwebParams: window.tvwebParams });
	}
	else {
		const bc = new BroadcastChannel("twModuleBC");
		await new Promise((resolve, reject) => {
			bc.onmessage = (msg => {
				switch (msg?.data.action) {
					case 'pageLoaded':
						bc.postMessage({ action: 'startTVweb', tvwebParams: window.tvwebParams });
						resolve(bc);
						break;
				}
			});


			const winProxy = window.open(tvwebUrl, '_tvweb');
			if (!winProxy) {
				reject("tvweb window could not be opened");
			}
		})
		bc.close(); // BroadcastChannel needs to be closed to be garbage collected
	}
	return true;
}

/**
 * Copied from extensionInterface.js
 * Sends Messages to browser Extension
 * @param {string} action Action to be performed by extension
 * @param {Object} data Parameters for the action
 * @returns Promise with response from extension
 */
function sendMessageToExtension(action, data){
    return new Promise((resolve, reject) => {
		if (!document.getElementById('tvwebBrowserExtensionInstalled'))	{
            reject(new Error("tvweb browser extension is not installed"));
            return;
        }
        document.addEventListener("fromEx_" + action, (event) => {
            if (event?.detail?.errMsg) {
                reject(new Error(event.detail.errMsg));
            }
            else {
                resolve(event?.detail);
            }
        }, { once: true });
        window.dispatchEvent(new CustomEvent("toTVwebExtension", { detail: {action: action, ...data }}));
    });
}