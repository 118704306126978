import axios from 'axios';
import authHeader from '../services/authHeader';
import { auth } from './authModule';
import accountStore from './accountStore';
import {eventBus} from "../main.js";

const StudyStore = {
  data: {
    studies: [],
    activeStudy: null,
    fetchedStudy: {patient: {study: {}}} ,
    currentStudyKey: {}    
  },
  
  methods: {
    clean(){
      StudyStore.data.studies = {};
      StudyStore.data.activeStudy = null;
      StudyStore.data.fetchedStudy = {patient: {study: {}}};
      StudyStore.data.currentStudyKeys = {};
    },
    async loadActiveStudy(medviewViewer) {

      if(!accountStore.data.fetched){
        var callback = function(){
          StudyStore.methods.loadStudyActual(medviewViewer);
        }
        
        accountStore.methods.fetch(null, callback);
      }else{
        StudyStore.methods.loadStudyActual(medviewViewer);
      }
    },
    
    loadStudyActual(medviewViewer){
      var account = accountStore.data.account;

      //store activeStudy in localStorage otherwise refreshing page doesn't work(tvWEB)
      if (StudyStore.data.activeStudy == null) {
        StudyStore.data.activeStudy =  JSON.parse(localStorage.getItem("activeStudy"));
      }
  
      axios.get('/v1/studies/' + StudyStore.data.activeStudy.id, { headers: authHeader() })
      .then(response => {
        StudyStore.data.fetchedStudy.patient.study = response.data.data;
        var study = StudyStore.data.fetchedStudy.patient.study;
        var patient = StudyStore.data.fetchedStudy.patient;

        study.description = StudyStore.data.activeStudy.description;
        
        let dat = new Date(study['date_time'])
        var isoDateTime = new Date(dat.getTime() - (dat.getTimezoneOffset() * 60000)).toISOString();
                
        try {
          study['date_time'] = isoDateTime; 
        } catch (e) {
          console.log("Error: wrong study date, " + e);
          study['date_time'] = null;
        }

        patient.name = account.firstNameDecrypted;
        patient.familyname = account.nameDecrypted;
        patient.displayName = account.fullNameDecrypted ? account.fullNameDecrypted : patient.name + " " + patient.familyname;
        patient.birthdate = account.birthdateDecrypted ? account.birthdateDecrypted : account.birthdate;
        patient.accountId = account.patientId;

        for(var i = 0; i<study.series.length; i++){
          for(var j = 0; j<study.series[i].images.length; j++){
            var image = study.series[i].images[j];
            image.uuid = image.id;
            image.sequence_number = image.sequenceNumber;
            image.number_of_frames = image.numberOfFrames;
          }
        }

        StudyStore.methods.loadActiveStudyKey(medviewViewer);
        eventBus.$emit('documentLoaded', StudyStore.data.activeStudy.id );
      }).catch(e => {
        console.log(e);
      })
    },
    loadActiveStudyKey(medviewViewer){
      axios.get('/v1/keys/study/' + StudyStore.data.activeStudy.id, { headers: authHeader() })
      .then(response => {
        console.log(response.data.data)
        let imagesKeys = {};
        response.data.data.forEach(key => { 
          let images = key.images;
          let decryptedAesKey = auth.actions.decryptAesKey(accountStore.data.currentRsaKey, key);
          
          images.forEach(image => imagesKeys[image] = decryptedAesKey);
        });
        StudyStore.data.currentStudyKeys = imagesKeys;
        
        if (medviewViewer) {
          medviewViewer.openStudySession(StudyStore.data.fetchedStudy)
        }
        
      }).catch(e => {
        console.log(e);
      })
    }
  }
}

export default StudyStore;