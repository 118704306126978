"use strict";
import axios from 'axios';
import JSZip from 'jszip';
import authHeader from '../services/authHeader';
import { auth } from '../store/authModule';
import accountStore from '../store/accountStore';
import { setPatientInfo } from "./dicom";
import { eventBus } from "../main.js";
import dedent from "dedent";
import i18n from "../i18n.js";
import VueSimpleAlert from "vue-simple-alert";
import Vue from "vue";

Vue.use(VueSimpleAlert);
const app = new Vue();

/* global BigInt */
let infoFiles = [];
let counter = 0;
let decryptedDicoms = [];
let decryptedJpegs = {};
let downloadStep = 0;
let requestBatch = 200;
let requestCounter = 0;
let filesMissed = 0;
let dicomIDs = null;

export default async function downloadContent(studyId, selected) {

    eventBus.$emit('updateProgressText', "");

    if (selected == "all") {
        await downloadJpegForStudy(studyId, false);
    } else if (selected == "dicom") {
        await downloadDicomForStudy(studyId, true);
    } else if (selected == "jpeg") {
        await downloadJpegForStudy(studyId, true);
    }
}

async function downloadDicomForStudy(studyId, closeProgress) {

    eventBus.$emit('updatePb', 0);

    let dicomsAndKeys = await collectDicomIdsAndKeys(studyId);

    //reset
    decryptedDicoms = [];
    counter = 0;
    filesMissed = 0;

    var decryptedDicomsCallback = async function (decryptedDicoms) {
        const account = accountStore.data.account;

        var callbackCall = function (newZipFile) {
            saveData(account.firstNameDecrypted + "^" + account.nameDecrypted + "DICOM" + ".zip", newZipFile, studyId, closeProgress);
        }

        if (account.dicomsHavePatientInfo) {
            let decompressedDicoms = await decompressDicoms(decryptedDicoms);
            await compressAllDicoms(decompressedDicoms, callbackCall);
        } else {
            let decompressedDicoms = await decompressDicoms(decryptedDicoms);

            // collect the patient data
            let name = account.fullNameDecrypted ? account.fullNameDecrypted : account.nameDecrypted + "^" + account.firstNameDecrypted + "^^^";

            let patientData = {
                name: name,
                birthdate: new Date(account.birthdateDecrypted ? account.birthdateDecrypted : account.birthdate),
                id: account.patientId,
                sex: account.sex
            }

            let newDicoms = await addHeader(decompressedDicoms, patientData)
            await compressAllDicoms(newDicoms, callbackCall);
        }


    }

    if (dicomsAndKeys != null) {
        eventBus.$emit('updatePb', 0);
        downloadAndDecryptDicomFiles(dicomsAndKeys, decryptedDicomsCallback);
    } else {
        eventBus.$emit('downloadDone', studyId);
        app.$alert(i18n.t("reUploadText"));
    }

}

async function downloadJpegForStudy(studyId, closeProgress) {

    eventBus.$emit('updatePb', 0);
    let jpegsAndKeys = await collectJpegIdsAndKeys(studyId);

    //reset
    decryptedJpegs = {};
    counter = 0;


    var jpegCallback = async function () {

        // collect the patient data
        const account = accountStore.data.account;

        let newZipFile = await compressAllJpegs(decryptedJpegs);
        saveData(account.firstNameDecrypted + "^" + account.nameDecrypted + "JPEG" + ".zip", newZipFile, studyId, closeProgress);
    }

    downloadAndDecryptJpegFiles(jpegsAndKeys, jpegCallback);
}

async function collectDicomIdsAndKeys(currentStudy) {

    let response = await axios.get('/v1/studies/' + currentStudy + '/dicoms', { headers: authHeader() });

    if (response.data.dicoms == null) {
        return null;
    }

    let keysResponse = await axios.get('/v1/keys/study/' + currentStudy, { headers: authHeader() });
    let imagesKeys = {};

    keysResponse.data.data.forEach(key => {
        let images = key.images;
        let decryptedAesKey = auth.actions.decryptAesKey(accountStore.data.currentRsaKey, key);
        
        images.forEach(image => imagesKeys[image] = decryptedAesKey);
    });

    

    return {
        keys: imagesKeys,
        dicoms: response.data.dicoms
    };
}

async function makeTextFiles(text) {

    var studyData = text.data.data;
    var seriesData = text.data.data.series;

    var studyName = studyData.name ? studyData.name : "Unknown Study"
    var studyDescription = studyData.description ? studyData.description : "/"
    var studyCreatedAt = studyData.date_time ? new Date(studyData.date_time) : "/"
    var studyrefPhysician = studyData.refphysician ? studyData.refphysician : "/"
    var studyComment = studyData.comment ? studyData.comment : "/"

    var studyInfo = dedent(`
        "name": ` + studyName + `
        "description": ` + studyDescription + `
        "createdAt": ` + studyCreatedAt + `,
        "refPhysician": ` + studyrefPhysician + `,
        "comment": ` + studyComment)

    var seriesInfo = ``;

    seriesData.forEach((serie) => {
        seriesInfo += dedent(`        
            "modality:" ` + serie.modality + `
            "number:" ` + serie.number + `
            "description :" ` + serie.description +
            ` `) + "\n"
    });

    var patData = accountStore.data.account;
    var date = patData.birthdateDecrypted ? patData.birthdateDecrypted : patData.birthdate;


    var patInfo = dedent(`
        "dateOfBirth": ` + date + `
        "familyName": ` + patData.nameDecrypted + `
        "name": ` + patData.firstNameDecrypted + `,
        "sex": ` + patData.sex)

    var studyBlob = new Blob([studyInfo], { type: 'text/plain' });
    var seriesBlob = new Blob([seriesInfo], { type: 'text/plain' });
    var patBlob = new Blob([patInfo], { type: 'text/plain' });

    infoFiles = [];

    readFile(studyBlob)
    readFile(seriesBlob)
    readFile(patBlob)
}

function readFile(data) {

    const reader = new FileReader();
    reader.readAsArrayBuffer(data)

    var callback = function (result) {
        infoFiles.push(result);
    }

    reader.onloadend = () => {
        callback(reader.result);
    }

}

async function collectJpegIdsAndKeys(currentStudy) {

    let response = await axios.get('/v1/studies/' + currentStudy, { headers: authHeader() });
    let keysResponse = await axios.get('/v1/keys/study/' + currentStudy, { headers: authHeader() });

    let imagesKeys = {};

    keysResponse.data.data.forEach(key => {
        let images = key.images;
        let decryptedAesKey = auth.actions.decryptAesKey(accountStore.data.currentRsaKey, key);
        images.forEach(image => imagesKeys[image] = decryptedAesKey);
    });

    eventBus.$emit('updateProgressText', "");
    await makeTextFiles(response)

    return {
        keys: imagesKeys,
        series: response.data.data.series,
    };
}

function downloadAndDecryptDicomFiles(dicomsAndKeys, callback) {

    eventBus.$emit('updateProgressText', "decryptingDicoms");
    downloadStep = 0;
    let images = [];

    let total = Object.keys(dicomsAndKeys.dicoms).length
    counter = 0;
    var progress = 0;
    var step = 100 / total;

    for (let i = 0; i < dicomsAndKeys.dicoms.length; i++) {
        let image = dicomsAndKeys.dicoms[i];

        images.push(image);
        progress += step;

        if (Math.ceil(progress) > 100) {
            progress = 100;
        }

        eventBus.$emit('updatePb', Math.ceil(progress));
    }

    dicomIDs = images;

    let length = dicomsAndKeys.dicoms.length;
    loadFilesAndDecrypt(images, dicomsAndKeys.keys, "dicom", length, callback, decryptedDicoms, 0, "DICOM");
}

async function sortImagesByFrames(serie) {
    serie.images.sort(
        function (a, b) {
            return a.sequenceNumber - b.sequenceNumber || a.frame_number - b.frame_number
        }
    );

    return serie;
}


async function downloadAndDecryptJpegFiles(jpegsAndKeys, callback) {

    eventBus.$emit('updateProgressText', "decryptingJpegs");
    downloadStep = 0;

    var total = 0;
    var progress = 0;
    let images = [];


    for (var j = 0; j < jpegsAndKeys.series.length; j++) {
        var serie = jpegsAndKeys.series[j];
        total += serie.images.length;
    }

    var step = 100 / total;

    for (var i = 0; i < jpegsAndKeys.series.length; i++) {

        var desc = serie.description ? serie.description : "Serie_" + i;

        serie = jpegsAndKeys.series[i];
        decryptedJpegs[desc] = [];

        if (serie.images[0].numberOfFrames > 0) {
            serie = await sortImagesByFrames(serie);
        } else {
            serie.images.sort((a, b) => (a.sequenceNumber > b.sequenceNumber) ? 1 : -1)
        }

        for (var l = 0; l < serie.images.length; l++) {

            progress += step;

            if (Math.ceil(progress) > 100) {
                progress = 100;
            }

            eventBus.$emit('updatePb', Math.ceil(progress));

            var image = serie.images[l];
            images.push({ "imageId": image.id, "desc": desc });
        }
    }

    loadFilesAndDecrypt(images, jpegsAndKeys.keys, "study", total, callback, decryptedJpegs, 0, "JPEG");
}



async function addHeader(dicoms, patientData) {
    let result = [];

    dicoms.forEach((async dicom => {
        // skip files without content        
        if (dicom && dicom.content) {
            try {
                result.push(await setPatientInfo(dicom, patientData));
            } catch (e) {
                console.log("Error adding dicom header" + e)
                console.log(dicom.filename)
            }

        }
    }));

    return result;
}

async function compressAllDicoms(dicoms, callback) {
    let zip = new JSZip();

    dicoms.forEach((file) => {
        if (file) {
            zip.file(file.filename, file.content, { binary: true });
        }
    });

    zip.generateInternalStream({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
            // 1: Best speed
            // ...
            // 9: Best compression
            level: 1,
        },
        streamFiles: true,
    }).accumulate().then(function (data) {
        callback(data);
        // data contains here the complete zip file as a uint8array (the type asked in generateInternalStream)
    });

    // return zip.generateAsync({ type: "blob", streamFiles: true }, function updateCallback(metadata) {
    //   eventBus.$emit('updatePb', Math.ceil(metadata.percent));
    //})
}


async function compressAllJpegs(jpegs) {

    let zip = new JSZip();

    eventBus.$emit('updateProgressText', "compressingJpegs");
    let infoFilesNames = ["study_info.txt", "series_info.txt", "patient_info.txt"];
    var count = 0;

    infoFiles.forEach(file => {
        zip.file(infoFilesNames[count], file, { binary: true });
        count++;
    });

    for (const [key, value] of Object.entries(jpegs)) {

        var ser = zip.folder(key);

        value.forEach((image) => {
            ser.file("IMG_" + image.index + ".jpeg", image.data, { binary: true });
        });
    }

    return zip.generateAsync({ type: "blob", streamFiles: true }, function updateCallback(metadata) {
        eventBus.$emit('updatePb', Math.ceil(metadata.percent));
    })
}


async function decompressDicoms(dicoms) {
    let decompressing = [];

    dicoms.forEach((dcm) => {
        decompressing.push(decompress(dcm.data, dcm.index))
    });

    return Promise.all(decompressing);
}

async function saveData(filename, content, studyId, final) {
    var blob = window.URL.createObjectURL(new Blob([content]));

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    a.href = blob;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(blob);

    if (final) {
        eventBus.$emit('updateProgressText', "downloadDone");
        eventBus.$emit('downloadDone', studyId);

        if (filesMissed > 0) {
            alert(filesMissed + i18n.t("of") + counter + i18n.t("filesMissed"));
        }
    } else {
        await downloadDicomForStudy(studyId, true);
    }

}

async function loadFilesAndDecrypt(images, keys, type, filesLength, callback, obj, index, fileType) {

    var limit = index + requestBatch;
    requestCounter = 0;

    var step = 100 / filesLength;

    if (limit > filesLength) {
        limit = filesLength;
    }

    eventBus.$emit('updateProgressText', "downloadingFiles");
    eventBus.$emit('updatePb', 0);

    for (let i = index; i < limit; i++) {

        let fileId;

        if (fileType == "JPEG") {
            fileId = images[i].imageId;
        } else {
            fileId = images[i];
        }

        let key = keys[fileId]
        let dataUrl;

        if (type == "study") {
            dataUrl = "/v1/images/";
        } else {
            dataUrl = "/v1/dicoms/";
        }

        var url = axios.defaults.baseURL + dataUrl + fileId;
        const myHeaders = new Headers();
        myHeaders.append('Authorization', authHeader().Authorization);
        myHeaders.append('Accept', "application/octet-stream, application/json, text/plain, */*");

        const myInit = {
            method: 'GET',
            headers: myHeaders,
            cache: 'default',
        };

        const myRequest = new Request(url);
        let repeatCounter = 0;

        doFetch(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter)
    }
}

async function doFetch(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter) {
    var subtleKey = await window.crypto.subtle.importKey("raw", Buffer.from(wordToByteArray(key.key.words)), { "name": "AES-CTR" }, false, ["decrypt"]);
    var subtleIv = Buffer.from(wordToByteArray(key.iv.words));

    fetch(myRequest, myInit)
        .then(async function (response) {
            if (response.status != 200) {
                repeatDownload(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter);
            } else {
                response.blob().then(async blob => {
                    blob.arrayBuffer().then(async (value) => {
                        let ciphertext = value;
                        var length = ciphertext.byteLength;
                        var chunkSize = 128; // chunkSize in bytes
                        var index = 0;
                        var chunks = [];
                        var aesCounter = bufToBn(subtleIv);
                        do {
                            var newCount = aesCounter + BigInt(index / 16); // index / 16 = number of blocks
                            var counterBuffer = bnToBuf(newCount);
                            var decrypted = await window.crypto.subtle.decrypt({ name: "AES-CTR", counter: counterBuffer, length: 128 }, subtleKey, ciphertext.slice(index, index + chunkSize)); // length in bits
                            chunks.push(new Uint8Array(decrypted));
                            index += chunkSize;
                        } while (index < length);
                        var mergedChunks = merge(chunks);

                        requestCounter++;

                        if ((requestCounter == requestBatch) && (counter < filesLength)) {
                            await loadFilesAndDecrypt(images, keys, type, filesLength, callback, obj, limit, fileType)
                        }

                        if (fileType == "JPEG") {
                            obj[images[i].desc].push({ "data": mergedChunks, "index": i });
                        } else {
                            obj.push({ "data": mergedChunks, "index": i });
                        }

                        counter++;
                        downloadStep += step;

                        if (counter == filesLength) {
                            eventBus.$emit('updateProgressText', "compressingDicoms");
                            eventBus.$emit('updatePb', 0);
                            callback(obj);
                        } else {
                            eventBus.$emit('updatePb', (Math.ceil(downloadStep) < 100 ? Math.ceil(downloadStep) : 100));
                        }
                    }).catch(function (e) {
                        console.log("Error 1: " + e);
                        repeatDownload(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter)
                    });
                }).catch(function (e) {
                    console.log("Error 2: " + e);
                    repeatDownload(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter)
                });
            }
        }).catch(function (e) {
            console.log("Error 3: " + e);
            repeatDownload(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter)
        });
}


function repeatDownload(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter) {
    if (repeatCounter < 3) {
        repeatCounter++;
        doFetch(myRequest, myInit, step, key, filesLength, images, keys, type, callback, obj, limit, fileType, i, repeatCounter)
    } else {
        //tried repeating 3 times; skip the file and bump the counters
        counter++;
        requestCounter++;
        filesMissed++;
    }
}

function wordToByteArray(wordArray) {
    var byteArray = [], word, i, j;
    for (i = 0; i < wordArray.length; ++i) {
        word = wordArray[i];
        for (j = 3; j >= 0; --j) {
            byteArray.push((word >> 8 * j) & 0xFF);
        }
    }
    return byteArray;
}

function bnToBuf(bn) {
    var hex = BigInt(bn).toString(16);
    if (hex.length % 2) {
        hex = '0' + hex;
    }
    var len = hex.length / 2;
    var u8 = new Uint8Array(16); // Ensure the length is 16 bytes
    var j = 0;
    // Fill the last len bytes of u8 with the hex values
    for (var i = 16 - len; i < 16; i++) {
        u8[i] = parseInt(hex.slice(j, j + 2), 16);
        j += 2;
    }
    return u8;
}

function bufToBn(buf) {
    var hex = [];
    var u8 = Uint8Array.from(buf);
    u8.forEach(function (i) {
        var h = i.toString(16);
        if (h.length % 2) { h = '0' + h; }
        hex.push(h);
    });
    return BigInt('0x' + hex.join(''));
}

// https://stackoverflow.com/a/49129872/9014097
function merge(chunks) {
    let size = 0;
    chunks.forEach(item => {
        size += item.length;
    });
    let mergedArray = new Uint8Array(size);
    let offset = 0;
    chunks.forEach(item => {
        mergedArray.set(item, offset);
        offset += item.length;
    });
    return mergedArray;
}

async function decompress(data, index) {

    try {
        let zipFile = await JSZip.loadAsync(data);
        let filename = Object.keys(zipFile.files)[0];

        let content = await zipFile.file(filename).async('arraybuffer');
        filename = dicomIDs[index] + filename.substring(filename.lastIndexOf('.'));

        return {
            content, filename
        }
    } catch (e) {
        console.log(e);

    }
}