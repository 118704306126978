/* global dh */
import axios from 'axios';

export const auth = {
  data: {
    hac: '',
    decryptedKey: null
  },
  namespaced: true,
  actions: {
    async login(user) {
      try {
        let birthdate = user.birthDateObj.toISOString();
        const response = await axios.post('/login', {
          code: user.derivedKey, birthdate: birthdate
        });
        if (response.data.access_token) {
          response.data.expiresAt = new Date().getTime() + response.data.expires_in * 1000;
          localStorage.setItem('user', JSON.stringify(response.data));
          return Promise.resolve(response.data);
        } else {
          auth.mutations.loginFailure();
          return Promise.reject("No access token");
        }
      } catch (error) {
        console.error(error);
        auth.mutations.loginFailure();
        return Promise.reject(error);
      }
    },
    logout({ commit }) {
      commit('logout');
    },
    decrpytUserKey(hac, userKey, callback){
      let key = localStorage.getItem('hacDecrypt');
      if(!key){
        let pbkdf2 = require('pbkdf2');
        pbkdf2.pbkdf2(hac, Buffer.from(userKey.priv.salt, "hex"), userKey.priv.iter, 32, 'sha256', async (err, derivedKey) => {
          if(err){
            callback(err);
            return;
          }
          key = derivedKey.toString('hex');
          localStorage.setItem('hacDecrypt', key);
          callback(err, this.decrpytRsaKeyActual(key, userKey));
        });
      }else{
        callback(null, this.decrpytRsaKeyActual(key, userKey));
      }
      
    },
    decrpytRsaKeyActual(key, userKey){
      var aesCryptor = dh.crypto.createSymetricCryptor( {key: key, iv: userKey.priv.iv} );

      try {
        // Decrypt private key to JSON string
        var pk = aesCryptor.decrypt(userKey.priv.key);
        var pkStr = aesCryptor.toUtf8(pk);

        // It is expected that pkStr contains private key as JSON string at
        // this point.

        var privateKey = JSON.parse(pkStr);
        return privateKey;
      }
      catch(e) {
        console.log("userKey decryption failed");
        console.log(e);
        return null;
      }
    },
    decryptAesKey(decryptedRsaKey, encryptedAesKey){
      var rsa = dh.crypto.createAsymetricCryptor(decryptedRsaKey);
      var decryptedAesKey = rsa.decryptAesKey(encryptedAesKey);
      return decryptedAesKey;
    }
  },
  mutations: {
    loginFailure() {
      localStorage.removeItem('user');
      localStorage.removeItem('hacDecrypt');
    },
    logout() {
      auth.mutations.loginFailure();
    }
  }
};