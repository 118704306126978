<template>
  <div class="main-content">
    <div :class="this.headerClass">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <a href="https://healthdataspace.org/"> <img width="170px" height="90px" :style="this.imgStyle" src="img/brand/logo.png" /> </a>
              <h1 :style="this.displayh1" class="text-white">{{ $t('titleText') }}</h1>
              <h3 :style="this.displayh2" class="text-white">{{ $t('titleText') }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      imgStyle : "marginBottom:40px",
      displayh1: "display:none",
      displayh2: "display:none",
      headerClass: "header py-7 py-lg-8"
    };
  },
  mounted () {
    this.adjustDisplay();
  }, 
  methods: {
    adjustDisplay() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      var mql = window.matchMedia("(min-device-width : 320px) and (max-device-width : 480px)");

      if (isMobile && mql.matches) {
        this.displayh2 = "display:block; margin-top:5px; margin-bottom:15px";
        this.displayh1 = "display:none";
        this.imgStyle = "marginBottom:0px"
        this.headerClass = "header py-6 py-lg-8"
      }else {
        this.displayh2 = "display:none";
        this.displayh1 = "display:block";
        this.headerClass = "header py-7 py-lg-8"
      }
    

    }
  } 

};
</script>
<style>
.main-content {
  height: 100%;
}
</style>
