import axios from 'axios';
import Vue from "vue";

export async function checkIfValidLicence() {

  const username = localStorage.getItem("hdsConnectUsername");
  const pwd = localStorage.getItem("hdsConnectPwd");

  if (!username || !pwd) {
    return false;
  }

  try {
    return await loginRequest(username, pwd);
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function loginRequest(username, passwordHash) {
  try {
    const response = await axios.post(`${Vue.prototype.$authUrl}/login`, {
      username: username,
      password: passwordHash,
    });

    if (response.data.roles[1] === "true") {
      return true;
    } else {
      localStorage.removeItem("hdsConnectUsername");
      localStorage.removeItem("hdsConnectPwd");
      return false;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}
