import accountStore from '../store/accountStore';
import authHeader from '../services/authHeader';
import axios from 'axios';
import { eventBus } from "../main.js";
import { auth } from '../store/authModule';

class TvWebDataLoader {
    constructor() {
        this.resetState();
        this.errorEventSent = false;
    }

    resetState() {
        this.tvWEBContent = [];
        this.counter = 0;
        this.studyCounter = 0;
        this.totalStudies = 0;
        this.fetchedStudy = { patient: { study: {} } };
        this.errorEventSent = false;
    }

    async waitForEntries(timeout = 2500) {
        const interval = 500;
        let waited = 0;

        while (!accountStore.data.account.entries && waited < timeout) {
            await new Promise(resolve => setTimeout(resolve, interval));
            waited += interval;
        }

        if (!accountStore.data.account.entries) {
            throw new Error("Entries not available");
        }
    }

    async prepareTvWebPayload() {
        this.resetState();

        try {
            await this.waitForEntries();
        } catch (error) {
            console.error(error);
            return;
        }

        const entries = accountStore.data.account.entries;
        const studies = Object.values(entries).filter(value => value.type === 'study');
        const size = studies.length;
        this.totalStudies = size;

        if (size === 0) {
            return;
        } else {
            for (const value of studies) {
                let studyId = value.id;
                await this.loadComplexStudyActual(studyId);
            }
        }
    }


    addPatientData(patientStudy) {
        const patientData = accountStore.data.account;
        patientStudy.patient.fullName = patientData.fullNameDecrypted;
        patientStudy.patient.name = patientData.firstNameDecrypted;
        patientStudy.patient.familyname = patientData.nameDecrypted;
        patientStudy.patient.displayName = `${patientData.firstNameDecrypted} ${patientData.nameDecrypted}`;
        patientStudy.patient.birthdate = patientData.birthdateDecrypted || patientData.birthdate;
        patientStudy.patient.accountId = patientData.patientId;
        patientStudy.patient.sex = patientData.sex;
    }

    async loadComplexStudyActual(studyId) {
        try {
            const response = await axios.get('/v1/studies/complex/' + studyId + "?dicom=true", { headers: authHeader() });
            this.fetchedStudy.patient.study = response.data.data;
            let patientStudy = this.fetchedStudy.patient.study;
            this.addPatientData(patientStudy)
            await this.decryptStudyKey(patientStudy);
        } catch (e) {
            if (e.response && e.response.status === 404 && !this.errorEventSent) {
                eventBus.$emit('tvWebStudyLoadError', { message: 'Error loading study', studyId });
                this.errorEventSent = true;
                return;
            }
        }
    }

    async decryptStudyKey(patientStudy) {
        try {

            for (let pos = 0; pos < patientStudy.patient.study.keys.length; pos++) {
                let key = patientStudy.patient.study.keys[pos];
                try {
                    let decryptedAesKey = auth.actions.decryptAesKey(accountStore.data.currentRsaKey, key);
                    patientStudy.patient.study.keys[pos].key = decryptedAesKey.key.toString();
                    this.counter++;
                } catch (keyerror) {
                    console.log(keyerror);
                    this.counter = 0;
                }
            }

            // one study processed
            if (this.counter === patientStudy.patient.study.keys.length) {
                this.studyCounter++;
                let originalStudy = this.fetchedStudy.patient.study;

                this.tvWEBContent.push(originalStudy);
                this.counter = 0;

                // all studies processed
                if (this.studyCounter === this.totalStudies) {
                    eventBus.$emit('dataReadyForTvWeb', this.tvWEBContent);
                    this.resetState();
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export default TvWebDataLoader;

