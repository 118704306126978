<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>
<script>
import axios from "axios";
import i18n from "./i18n.js";
import Vue from 'vue';

axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

export default {
  beforeCreate: function () {
    axios.defaults.baseURL = this.$serverUrl;
    Vue.prototype.$baseUrl = this.$baseUrl

    Vue.prototype.$activeReport;
    Vue.prototype.$activeDocument;
    Vue.prototype.$reportName;
    Vue.prototype.$medviewScript = document.createElement('script')
    Vue.prototype.$medviewScript.setAttribute('src', "medview/STABLE/medview/MedView_en.js")
    document.head.appendChild(Vue.prototype.$medviewScript)
  },
  
  data() {
    return {
      i18n
    }
  },

  components: {},
};
</script>

